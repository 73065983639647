/*!

=========================================================
* Material Dashboard PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AppLayout from "layouts/App.js";
import { CALLBACKURL, AUTH_CONFIG, SENTRY_CONFIG } from "variables/project.js";
import configureStore from "variables/configureStore";
import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: SENTRY_CONFIG.dsn,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const store = configureStore();

const getClientId = function () {
  /*console.log('environmet: ', process.env.NODE_ENV);
  switch (process.env.NODE_ENV) {
    case 'production':
      return constVars.AUTH_CLIENT_ID_PRODUCTION;

    case 'internal-prod':
      return constVars.AUTH_CLIENT_ID_INTERNAL_PROD;

    case 'preprod':
      return constVars.AUTH_CLIENT_ID_PREPROD;

    case 'verify':
      return constVars.AUTH_CLIENT_ID_VERIFY;

    case 'test':
      return constVars.AUTH_CLIENT_ID_TEST;

    case 'development':
      return constVars.AUTH_CLIENT_ID_TEST;

    default:
      throw new Error('Invalid environment variable is being used!');
  }*/
  return AUTH_CONFIG.clientId;
};

const Auth0ProviderWithHistory = ({ children }) => {
  return (
    <Auth0Provider
      domain={AUTH_CONFIG.domain}
      clientId={getClientId()}
      redirectUri={CALLBACKURL}
      audience={AUTH_CONFIG.audience}
      domain={AUTH_CONFIG.domain}
    >
      {children}
    </Auth0Provider>
  );
};

const history = createBrowserHistory();

ReactDOM.render(
  <Auth0ProviderWithHistory>
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route path="/app/*" component={AppLayout} />
          <Redirect from="/*" to="/app/dashboard"></Redirect>
        </Switch>
      </Router>
    </Provider>
  </Auth0ProviderWithHistory>,
  document.getElementById("root")
);
