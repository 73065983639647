import React, { useState, useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import { getActiveOrg } from "variables/coreWeb";

import { AUTH_CONFIG, APIURL, LABELSURL } from "variables/project.js";
import { useAuth0 } from "@auth0/auth0-react";

import { bottomData, topData } from "./dummyDashboardData";
import DashboaordBox from "components/DashboardBox/DashboaordBox";
import CardHeader from "components/Card/CardHeader";
import { FormControl, InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Button from "components/CustomButtons/Button.js";

import { useHistory } from "react-router-dom";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);
const useLocalStyles = makeStyles(() => ({
  flexRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    "& p": {
      margin: 0,
      maxWidth: '75%'
    }
  },
  cardBody: {
    display: 'flex',
    paddingTop: 40,
    paddingBottom: 40
  },
  roundedButton: {
    borderRadius: 20
  },
  formControl: {
    minWidth: 330,
    "& .MuiOutlinedInput-root": {
      borderRadius: 160,
      color: '#464646',
      '& fieldset': {
        borderColor: '#464646'
      },
      '&.Mui-focused hover': {
        borderColor: '#000'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#000'
      }
    },
    "& label": {
      color: '#464646'
    },
    "& legend": {
      width: 0
    }
  }
}));

export default function Dashboard() {
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
    isLoading,
  } = useAuth0();
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const [simpleSelect, setSimpleSelect] = useState();

  const [topData, setTopData] = useState([]);
  const [bottomData, setBottomData] = useState([]);
  const [techniqueId, setTechniqueId] = useState(0);
  const [techniqueOptions, setTechniqueOptions] = useState([{ text: "GolfSwing - Face On", value: 1 }, { text: "GolfSwing - DTL", value: 2 }]);

  const history = useHistory();

  console.log(simpleSelect);

  const [token, setToken] = useState(null);
  useEffect(() => {
    const fn = async () => {
      setToken(
        await getAccessTokenSilently({ audience: AUTH_CONFIG.audience })
      );
    };
    fn();
  }, [!isLoading && isAuthenticated]);

  useEffect(() => {
    if (token != null) {
      fetch(APIURL + "Read_Self_Profile", {
        // content-type header should not be specified!
        method: "POST",
        body: JSON.stringify({}),
        headers: {
          Authorization: `Bearer ` + token,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => console.log(error));
    }
  }, [isAuthenticated && !isLoading && token]);

  useEffect(() => {
    if (token != null) {
      fetch(LABELSURL + "playerDashboard", {
        // content-type header should not be specified!
        method: "POST",
        body: JSON.stringify({
          techniqueId: techniqueId
        }),
        headers: {
          Authorization: `Bearer ` + token,
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          if (response.func_status === 1) {
            setTopData(response.topData);
            setBottomData(response.bottomData);

          }
          else {

          }
          console.log("Dashboard: " + JSON.stringify(response));
        })
        .catch((error) => console.log(error));
    }
  }, [isAuthenticated && !isLoading && token, techniqueId]);

  const handleSimple = event => {
    setTechniqueId(event.target.value);
  };


  return (
    <div>
      <GridContainer justify="center">
        <GridItem lg={12}>
          <Card>
            <CardBody className={localClasses.cardBody}>
              <GridContainer justify={"center"}>
                {topData.map((el) => (
                  <GridItem lg={3}>
                    <DashboaordBox
                      topBgColor={el.bgColor}
                      topText={el.topText}
                      bottomText={el.bottomText}
                    />
                  </GridItem>
                ))}
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem lg={12}>
          <Card>
            <CardBody className={localClasses.cardBody}>
              <GridItem md={12} lg={12}>
                <div className={localClasses.flexRow}>
                  <p>
                    Upload a video to extract the biomechanics of the sport
                    technique. <br></br>Once uploaded you can compare videos in
                    three ways:
                    <ul>
                      <li>Pro vs Pro</li>
                      <li>Pro vs You</li>
                      <li>You vs You</li>
                    </ul>
                    <p>
                      Our AI gives you all the biomechanical information you
                      need to get better and maintain it over time
                    </p>
                  </p>
                </div>
              </GridItem>
              <GridItem md={12} lg={12}>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />

                <Button
                  color="success"
                  className={localClasses.roundedButton}
                  onClick={() => {
                    history.push("/app/uploads");
                  }}
                >
                  Upload video
                </Button>
              </GridItem>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem lg={12}>
          <Card className={localClasses.cardBody}>
            <CardHeader>
              <GridItem lg={12}>
                <h4 style={{ margin: 0 }}>Technique</h4>
              </GridItem>
            </CardHeader>
            <CardBody>
              <div className={localClasses.flexRow}>
                <GridItem lg={4}>
                  <FormControl variant="outlined" className={localClasses.formControl} margin="dense" hiddenLabel>
                    <Select
                      native
                      value={techniqueId}
                      onChange={handleSimple}
                      label="Dummy Select"
                      inputProps={{
                        name: 'dummySelect',
                        id: 'dummy-select',
                      }}
                    >
                      <option aria-label="None" value="0" />
                      {techniqueOptions.map((option, index) => {
                        return (<option value={option.value}>{option.text}</option>);
                      })}

                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem lg={3}>
                  <p style={{ fontSize: 17, maxWidth: 'none', textAlign: 'right' }}>Data from last 30 days</p>
                </GridItem>
              </div>
              <div className={localClasses.flexRow}>
                {bottomData.map((el) => (
                  <GridItem lg={2} style={{ marginTop: 40, marginBottom: 40 }}>
                    <DashboaordBox
                      topBgColor={el.bgColor}
                      topText={el.topText}
                      bottomText={el.bottomText}
                    />
                  </GridItem>
                ))}
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
