import React from "react";

const DEBUG = false;

const SERVERURL = "https://api.eleetz.com";

const APIURL = SERVERURL + "/api/";

const LABELSURL = SERVERURL + "/labels/";

const METRICSURL = SERVERURL + "/metrics/";

const CALLBACKURL = DEBUG
  ? "http://localhost:3000/app/dashboard"
  : "https://web.eleetz.com/app/dashboard";

const UPLOADSPATH = "http://uploads.eleetz.com/";

const REPORTSURL = DEBUG ? "http://localhost:3001/report/" : "https://reports.eleetz.com/report/";

export const AUTH_CONFIG = {
  domain: "eleetz.eu.auth0.com",
  clientId: "S6EwjIoBtmXTPznFnJVIP0n0nnrJQJGq",
  audience: "api.eleetz.com",
  callbackUrl: CALLBACKURL,
};

export const SENTRY_CONFIG = {
  dsn: "https://a5fc226720764147b0b07b7509117c94@o1084754.ingest.sentry.io/6094753"
};

export { APIURL, LABELSURL, METRICSURL, CALLBACKURL, UPLOADSPATH, DEBUG, REPORTSURL };
