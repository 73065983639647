export const dummyVideoList = [
    {
        thumbnail: 'https://images.unsplash.com/photo-1635866869385-fabb68f0dea0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzNjM2NzEzOA&ixlib=rb-1.2.1&q=80&w=1080',
        name: 'Bla Bla Bla',
        updateDate: '2021-04-20',
        checked: true
    },
    {
        thumbnail: 'https://images.unsplash.com/photo-1635866869385-fabb68f0dea0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzNjM2NzEzOA&ixlib=rb-1.2.1&q=80&w=1080',
        name: 'Bla Bla Bla',
        updateDate: '2021-04-20',
        checked: false
    },
    {
        thumbnail: 'https://images.unsplash.com/photo-1635866869385-fabb68f0dea0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzNjM2NzEzOA&ixlib=rb-1.2.1&q=80&w=1080',
        name: 'Bla Bla Bla',
        updateDate: '2021-04-20',
        checked: false
    },
    {
        thumbnail: 'https://images.unsplash.com/photo-1635866869385-fabb68f0dea0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTYzNjM2NzEzOA&ixlib=rb-1.2.1&q=80&w=1080',
        name: 'Bla Bla Bla',
        updateDate: '2021-04-20',
        checked: false
    }
]

export const tableHeader = [
    'Videos',
    'Names',
    'Update Date',
    'Technique',
    'Select for Comparison'
]