export const dummyTableData = [
    {
        name: "Tiger Woods",
        shoulder: "10",
        hipAngle: "-10",
        kneeAngle: "-2",
        angleAnkel: "9",
        date: '2021-09-10',
    },
    {
        name: "Tiger Woods",
        shoulder: "10",
        hipAngle: "-10",
        kneeAngle: "-2",
        angleAnkel: "9",
        date: '2021-09-10',
    },
    {
        name: "Tiger Woods",
        shoulder: "10",
        hipAngle: "-10",
        kneeAngle: "-2",
        angleAnkel: "9",
        date: '2021-09-10',
    },
    {
        name: "Tiger Woods",
        shoulder: "10",
        hipAngle: "-10",
        kneeAngle: "-2",
        angleAnkel: "9",
        date: '2021-09-10',
    },
    {
        name: "Tiger Woods",
        shoulder: "10",
        hipAngle: "-10",
        kneeAngle: "-2",
        angleAnkel: "9",
        date: '2021-09-10',
    },
    {
        name: "Tiger Woods",
        shoulder: "10",
        hipAngle: "-10",
        kneeAngle: "-2",
        angleAnkel: "9",
        date: '2021-09-10',
    },
    {
        name: "Tiger Woods",
        shoulder: "10",
        hipAngle: "-10",
        kneeAngle: "-2",
        angleAnkel: "9",
        date: '2021-09-10',
    },
];

export const columns = [
    {
        label: 'Persons',
        labelAdornment: '',
        id: 'name'
    },
    {
        label: 'Shoulder Angle',
        labelAdornment: 'P1',
        id: 'shoulder'
    },
    {
        label: 'Hip Angle',
        labelAdornment: 'P1',
        id: 'hipAngle'
    },
    {
        label: 'Knee Angle',
        labelAdornment: 'P1',
        id: 'kneeAngle'
    },
    {
        label: 'Angle Ankel',
        labelAdornment: 'P1',
        id: 'angleAnkel'
    },
    {
        label: 'Hip Angle2',
        labelAdornment: 'P1',
        id: 'hipAngle2'
    },
    {
        label: 'Knee Angle2',
        labelAdornment: 'P1',
        id: 'kneeAngle2'
    },
    {
        label: 'Angle Ankel2',
        labelAdornment: 'P1',
        id: 'angleAnkel2'
    }, {
        label: 'Hip Angle3',
        labelAdornment: 'P1',
        id: 'hipAngle3'
    },
    {
        label: 'Knee Angle3',
        labelAdornment: 'P1',
        id: 'kneeAngle3'
    },
    {
        label: 'Angle Ankel2',
        labelAdornment: 'P1',
        id: 'angleAnkel3'
    },
    {
        label: 'Hip Angle4',
        labelAdornment: 'P1',
        id: 'hipAngle4'
    },
    {
        label: 'Knee Angle4',
        labelAdornment: 'P1',
        id: 'kneeAngle4'
    },
    {
        label: 'Angle Ankel4',
        labelAdornment: 'P1',
        id: 'angleAnkel4'
    }
];
