import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

const analysisStyles = {
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        wordWrap: "break-word",
        background: "#FFF",
        boxShadow: "0px 1px 38px 0 rgb(0 0 0 / 44%)",
        overflow: "hidden",
        width: "100%",
    },
    topSide: {
        color: "white",
        width: "100%",
        flex: "1",
        padding: "1.7em 0",
        fontSize: "14px",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "20px",
    },
    bottomSide: {
        color: "white",
        width: "100%",
        flex: "1",
        padding: "1.7em 0",
        fontSize: "20px",
        fontWeight: "bold",
        textAlign: "center",
        lineHeight: "20px",
    },
};

const useAOStyles = makeStyles(analysisStyles);

const DashboaordBox = ({ topBgColor, bottomBgColor, topText, bottomText }) => {
    const AOClasses = useAOStyles();

    return <div className={AOClasses.container}>
        <Box
            component="div"
            p={1}
            className={`${AOClasses.topSide} title-box`}
            style={{ backgroundColor: topBgColor }}
        >
            {topText}
        </Box>
        <Box
            component="div"
            p={1}
            className={`${AOClasses.bottomSide} title-disk`}
            style={{ backgroundColor: '#282828' }}
        >
            {bottomText}
        </Box>
    </div>;
};

export default DashboaordBox;
