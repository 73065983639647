import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles, Icon, FormControl, Select, InputLabel } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { dummyPlayerData } from 'components/PlayerCard/dummyPlayerData';
import { dummyTableData } from 'components/PlayerCard/dummyTableData';
import { columns } from 'components/PlayerCard/dummyTableData';
import PlayerCard from 'components/PlayerCard/PlayerCard';
import clsx from 'clsx';

import { defaultFont } from 'assets/jss/material-dashboard-pro-react';
import { useAuth0 } from "@auth0/auth0-react";
import { AUTH_CONFIG, LABELSURL, METRICSURL } from "../../variables/project";
import CalendarIcon from 'components/CustomIcons/CalendarIcon';
import { selectData } from './selectData';

const useLocalStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: "800vh",
        height: '100%',
        color: '#fff',
        "& .MuiTableCell-root": {
            ...defaultFont,
            color: '#fff'
        },
        "& .MuiTableCell-stickyHeader": {
            zIndex: 1
        },
        "& table": {
            whiteSpace: 'nowrap'
        }
    },
    tableHead: {
        "& tr": {
            height: 80
        },
        "& th": {
            backgroundColor: '#282828',
            borderBottom: '1px solid #464646'
        },
        "& th:first-child": {
            borderBottom: 'none !important',
            zIndex: 2
        }
    },
    label: {
        padding: '12px 16px',
        backgroundColor: '#464646',
        color: '#fff'
    },
    personsLabel: {
        backgroundColor: '#1EAD9E',
        fontWeight: 'bold',
        textTransform: 'uppercase'
    },
    labelAdornment: {
        color: '#1EAD9E'
    },
    tableCell: {
        border: '1px solid #464646'
    },
    bodyRows: {
        height: 120,
        "& span": {
            fontWeight: 700,
            fontSize: '1.5rem'
        },
        "& td:first-child": {
            position: 'sticky',
            left: 0,
            zIndex: 1,
            backgroundColor: '#282828',
            "& span": {
                padding: '12px 16px',
                backgroundColor: '#464646',
                color: '#1EAD9E',
                fontWeight: 300,
                fontSize: '1rem !important',
            }
        }
    },
    playerNameInfoContainer: {
        position: 'relative',
        padding: 20,
        minWidth: 150
    },
    playerNameInfoContainerTop: {
        padding: 10,
        backgroundColor: '#01B3A3',
        fontWeight: 'bold',
        fontSize: '15px'
    },
    playerNameInfoContainerBottom: {
        padding: 10,
        backgroundColor: '#037E73',
    },
    playerNameInfoContainerIcon: {
        position: 'absolute',
        top: -5,
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#01B3A3',
        padding: 8,
        borderRadius: '50%',
        display: 'flex',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
    },
    formControl: {
        width: '100%',
        marginBottom: 25,
        "& .MuiInputBase-root": {
            border: '1px solid #464646',
            borderRadius: 160,
            color: '#464646',
            '&:before, &:after': {
                content: 'none'
            }
        },
        "& label": {
            color: '#fff',
        },
        "& select": {
            paddingLeft: '1rem',
            color: '#fff'
        },
    }
}));

function CompareTable(props) {
    const classes = useLocalStyles();
    const [cols, setCols] = useState(columns);
    const [rows, setRows] = useState(dummyTableData);
    const [filters, setFilters] = useState({});
    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        logout,
        loginWithRedirect,
        isLoading,
    } = useAuth0();
    const [token, setToken] = useState(null);
    useEffect(() => {
        const fn = async () => {
            setToken(
                await getAccessTokenSilently({ audience: AUTH_CONFIG.audience })
            );
        };
        fn();
    }, [!isLoading && isAuthenticated]);

    useEffect(() => {
        if (token) {
            getTableData();
        }
    }, [token]);

    const getTableData = function () {
        fetch(METRICSURL + "compareView", {
            // content-type header should not be specified!
            method: "POST",
            body: JSON.stringify({

            }),
            headers: {
                Authorization: `Bearer ` + token,
                "Content-Type": "application/json",
            },
        })
            .then((response) => { return response.json(); })
            .then((response) => {
                // Do something with the successful response
                if (response.func_status === 1) {
                    //setRows(response.rows);
                    //setCols(response.columns);
                }
            })
            .catch((error) => console.log(error));
    };

    const handleSelectFilter = (e) => {
        setFilters(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value
        }));
    };

    return (
        <>
            <GridContainer style={{ backgroundColor: "#282828", marginTop: -30, marginBottom: 25, padding: '20px 40px' }}>

                {selectData.map(select => (
                    <GridItem lg={3} key={select.name}>
                        <FormControl className={classes.formControl}>
                            <InputLabel variant="outlined" htmlFor={select.label}>
                                {select.label}
                            </InputLabel>
                            <Select
                                native
                                value={filters[select.name]}
                                onChange={handleSelectFilter}
                                label={select.label}
                                inputProps={{
                                    name: select.name,
                                    id: select.name,
                                }}
                            >
                                {select.options.map(option => (
                                    <option value={option} key={option}>{option}</option>
                                ))}
                            </Select>
                        </FormControl>
                    </GridItem>
                ))}

            </GridContainer>
            <GridContainer style={{ backgroundColor: "#282828", padding: 50, height: "90vh" }} justify="center">
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader>
                        <TableHead classes={{ root: classes.tableHead }}>
                            <TableRow>
                                {cols.map((column, index) => (
                                    <TableCell key={column.id} align='center'>
                                        {column.label && index !== 0
                                            ?
                                            <span className={classes.label}>
                                                <span className={classes.labelAdornment}>{column.labelAdornment} </span>
                                                - {column.label}
                                            </span>
                                            :
                                            <span className={clsx([classes.label, classes.personsLabel])}>
                                                {column.label}
                                            </span>
                                        }
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map(row => (
                                <TableRow className={classes.bodyRows}>
                                    {cols.map((column, index) => {
                                        const value = row[column.id];
                                        const date = row.date;
                                        return (
                                            <>
                                                {index === 0
                                                    ?
                                                    <TableCell key={column.id} align='center' className={clsx([classes.tableCell, classes.rowHead])}>
                                                        <div className={classes.playerNameInfoContainer}>
                                                            <div className={classes.playerNameInfoContainerTop}>
                                                                {date}
                                                            </div>
                                                            <div className={classes.playerNameInfoContainerBottom}>
                                                                {value}
                                                            </div>
                                                            <div className={classes.playerNameInfoContainerIcon}>
                                                                <CalendarIcon />
                                                            </div>
                                                        </div>

                                                    </TableCell>
                                                    :
                                                    <TableCell key={column.id} align='center' className={classes.tableCell}>
                                                        <span>{value}</span>
                                                    </TableCell>
                                                }
                                            </>
                                        );
                                    })}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </GridContainer>
        </>
    );


}

export default CompareTable;