import { Divider, makeStyles } from '@material-ui/core';
import Card from 'components/Card/Card';
import React from 'react';

import { primaryBoxShadow } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles(() => ({
    card: {
        backgroundColor: '#464646',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: 20
    },
    leftSide: {
        marginRight: 30
    },
    img: {
        width: 100,
        borderRadius: 5
    },
    sportLabel: {
        width: 'fit-content',
        padding: 5,
        paddingLeft: 10,
        paddingRight: 10,
        fontWeight: 'bold',
        textTransform: 'uppercase',
        borderRadius: 20
    },
    nameRow: {
        display: 'flex',
        alignItems: 'center',
        "& p": {
            fontWeight: 'bold',
            margin: 0
        }
    },
    rightSide: {
        "& h5": {
            fontWeight: 'bold',
            fontSize: '1.2rem'
        }
    },
    flagContainer: {
        width: 30,
        height: 30,
        marginRight: 20,
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        ...primaryBoxShadow,
        "& img": {
            width: 20,
        }
    }
}));

const PlayerCard = ({ player }) => {
    const classes = useStyles();

    const { name, location, sportText, sportLabelBackgroundColor, schoolBadgeURL, schoolName, imageURL } = player;

    return (
        <Card className={classes.card}>
            <div className={classes.leftSide}>
                <img src={imageURL} alt={name} className={classes.img} />
            </div>
            <div className={classes.rightSide}>
                <div className={classes.nameRow}>
                    <div className={classes.flagContainer}>
                        <img src={location} alt={schoolName} />
                    </div>
                    <div>
                        <h5>{name}</h5>
                        <p style={{ backgroundColor: sportLabelBackgroundColor }} className={classes.sportLabel}>{sportText}</p>
                    </div>
                </div>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} variant="middle" />
                <div className={classes.nameRow}>
                    <div className={classes.flagContainer}>
                        <img src={schoolBadgeURL} alt={name} />
                    </div>
                    <p>{schoolName}</p>
                </div>
            </div>
        </Card>
    );
};

export default PlayerCard;
