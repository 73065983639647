import React from 'react';

const CalendarIcon = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.638 8.22793V6.72192H2.36279V13.6862L7.64326 13.6863V13.1884H7.17164C7.17164 12.981 7.17164 11.738 7.17164 11.5307H7.91147V11.0328H7.17164V9.37547L9.32712 9.3753L9.32718 7.21983H10.9846V8.28037H11.4825V7.21983H13.1402V8.22793H13.638ZM5.01617 9.37527H6.67367V11.0328H5.01617V9.37527ZM7.17164 7.21983H8.82921V8.8774H7.17164V7.21983ZM5.01617 7.21983H6.67374V8.8774H5.01617V7.21983ZM2.8607 7.21983H4.5183V8.8774H2.8607V7.21983ZM2.8607 9.37527H4.5183V11.0328H2.8607V9.37527ZM4.51827 13.1883H2.8607V11.5307H4.5183V13.1883H4.51827ZM5.01617 13.1883V11.5307H6.67367V13.1883H5.01617Z" fill="white" />
            <path d="M12.4255 8.87744C10.1861 8.87744 8.36426 10.6993 8.36426 12.9387C8.36426 13.9596 8.74466 14.9354 9.43542 15.6864L9.80191 15.3493C9.19589 14.6905 8.86217 13.8344 8.86217 12.9387C8.86217 10.9739 10.4607 9.37535 12.4255 9.37535C14.3904 9.37535 15.9889 10.9739 15.9889 12.9387C15.9889 14.9035 14.3904 16.5021 12.4255 16.5021C11.5899 16.5021 10.7778 16.2069 10.1388 15.671L9.81881 16.0525C10.5474 16.6635 11.4731 17 12.4255 17C14.6649 17 16.4868 15.1781 16.4868 12.9387C16.4868 10.6993 14.6649 8.87744 12.4255 8.87744V8.87744Z" fill="white" />
            <path d="M12.1611 10.1897V13.4319H14.4897V12.934H12.659V10.1897H12.1611Z" fill="white" />
            <path d="M1.01158 5.83261H2.3629V5.33471H1.01158V1.62132H2.09569V2.85911H2.09572V2.85921H4.09425V2.85911H4.09429V1.62132H5.36594V2.85911H5.36611V2.85921H7.36461V1.62132H8.63646V2.85921H10.635V2.85911H10.6351V1.62132H11.9068V2.85911H11.9068V2.85921H13.9053V2.85911H13.9054V1.62132H14.9895V5.33471H2.8608V5.83261H14.9895V9.04146H15.4874V1.12341H13.9053V0.620027L13.4442 0H12.3679L11.9068 0.620027V1.12341H10.635V0.620027L10.1738 0H9.09759L8.63643 0.620027V1.12341H7.36457V0.620027L6.90344 0H5.82724L5.36607 0.620027V1.12341H4.09425V0.620027L3.63309 0H2.55688L2.09572 0.620027V1.12341H0.513672V14.9921H7.96867V14.4942H1.01158V5.83261ZM12.6181 0.497907H13.194L13.4074 0.7849V2.36121H12.4047V0.7849L12.6181 0.497907ZM9.13436 0.7849L9.3478 0.497907H9.92365L10.1371 0.7849V2.36121H9.13447V1.12341H9.1344V0.7849H9.13436ZM6.07742 0.497907H6.65326L6.8667 0.7849V1.12341H6.86663V2.36121H5.86401V0.7849L6.07742 0.497907ZM2.80706 0.497907H3.38294L3.59638 0.7849V2.36121H2.59363V0.7849L2.80706 0.497907Z" fill="white" />
            <path d="M9.72656 4.51671H9.22865V4.0188H9.72656V4.51671ZM8.23284 4.51671H7.73493V4.0188H8.23284V4.51671ZM6.73912 4.51671H6.24121V4.0188H6.73912V4.51671Z" fill="white" />
            <path d="M9.54736 12.9341H10.0453V13.432H9.54736V12.9341Z" fill="white" />
            <path d="M12.1802 15.3455H12.6781V15.8434H12.1802V15.3455Z" fill="white" />
        </svg>
    );
};

export default CalendarIcon;
