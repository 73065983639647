import { React, useEffect, useState } from "react";
import {
    makeStyles,
    TableHead,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Checkbox,
} from "@material-ui/core";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button.js";

import play_button from "../../assets/img/play-button.svg";
import play_button_red from "../../assets/img/play-button-red.svg";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import checkboxStyles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import { dummyVideoList, tableHeader } from "./dummyData";
import Check from "@material-ui/icons/Check";
import {
    APIURL,
    AUTH_CONFIG,
    LABELSURL,
    UPLOADSPATH,
} from "../../variables/project";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
const useStyles = makeStyles(styles);
const useCheckboxStyles = makeStyles(checkboxStyles);
const useLocalStyles = makeStyles(() => ({
    row: {
        display: "flex",
        justifyContent: "flex-end",
    },
    table: {
        "& th, & td": {
            color: "#FFF",
            border: "none",
            fontWeight: 300,
            fontSize: 13,
        },
        "& button": {
            marginRight: 12,
        },
    },
    thumbnail: {
        position: "relative",
        maxWidth: 148,
    },
    playButton: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
    },
}));

const VideoList = () => {
    const classes = useStyles();
    const localClasses = useLocalStyles();
    const checkboxClasses = useCheckboxStyles();
    const [checked, setChecked] = useState([]);
    const [logo, setLogo] = useState(require("assets/img/logo_mini.svg").default);

    const [videosFound, setVideosFound] = useState([]);

    const history = useHistory();

    const {
        user,
        isAuthenticated,
        getAccessTokenSilently,
        logout,
        loginWithRedirect,
        isLoading,
    } = useAuth0();

    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);
    useEffect(() => {
        const fn = async () => {
            setToken(
                await getAccessTokenSilently({ audience: AUTH_CONFIG.audience })
            );
        };
        fn();
    }, [!isLoading && isAuthenticated]);

    useEffect(() => {
        if (token) {
            getUploads();
        }
    }, [token]);

    const getUploads = function () {
        setLoading(true);

        fetch(LABELSURL + "sportVideos", {
            // content-type header should not be specified!
            method: "POST",
            body: JSON.stringify({
                techniqueId: 1,
            }),
            headers: {
                Authorization: `Bearer ` + token,
                "Content-Type": "application/json",
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                // Do something with the successful response
                if (response.func_status === 1) {
                    setVideosFound(response.videos);
                }
            })
            .catch((error) => console.log(error))
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={11} className={localClasses.row}>
                    <Button
                        color="success"
                        variant="contained"
                        style={{ borderRadius: 20 }}
                    >
                        Upload Video
                    </Button>
                    {checked.length == 2 ? (
                        <>
                            <Button
                                color="success"
                                onClick={() => {
                                    history.push(
                                        "/app/analysis/" + checked[0] + "/" + checked[1]
                                    );
                                }}
                                style={{ borderRadius: 20, marginRight: 40 }}
                            >
                                Position Analysis
                            </Button>
                            <Button
                                color="success"
                                onClick={() => {
                                    history.push("/app/insights/3099/3147");
                                }}
                                style={{ borderRadius: 20 }}
                            >
                                Data Compare
                            </Button>
                        </>
                    ) : (
                        ""
                    )}
                </GridItem>
            </GridContainer>
            <GridContainer justify="center" style={{ height: "100vh" }}>
                <GridItem xs={12} sm={12} md={11}>
                    <Card style={{ height: "80vh" }}>
                        <CardHeader color="success" icon>
                            <CardIcon color="success">
                                <img src={play_button} alt="play button" />
                            </CardIcon>
                            <h4 className={classes.cardIconTitle} style={{ paddingLeft: 60 }}>
                                All Videos
                                <small style={{ display: "block", marginTop: 8 }}>
                                    Choose video you want to compare
                                </small>
                            </h4>
                        </CardHeader>
                        <CardBody style={{ overflowY: "auto", height: "100%" }}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={11}>
                                    {loading ? (
                                        <>
                                            <h3>Loading Videos...</h3>
                                            <CustomLinearProgress />
                                        </>
                                    ) : (
                                        <Table className={localClasses.table}>
                                            <TableHead>
                                                <TableRow>
                                                    {tableHeader.map((h) => (
                                                        <TableCell
                                                            align={
                                                                h === "Select for Comparison"
                                                                    ? "center"
                                                                    : "left"
                                                            }
                                                        >
                                                            {h}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {videosFound.map((v) => (
                                                    <TableRow key={v.name}>
                                                        <TableCell align="left">
                                                            <div className={localClasses.thumbnail}>
                                                                <img
                                                                    style={{ width: "100%" }}
                                                                    src={
                                                                        "http://api.eleetz.com/labels/getImage/" +
                                                                        v.fileId
                                                                    }
                                                                    onError={(e) => {
                                                                        e.target.onerror = null;
                                                                        e.target.src = logo;
                                                                    }}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        <TableCell align="left">{v.name}</TableCell>
                                                        <TableCell align="left">
                                                            {v.createdOn_UTC}
                                                        </TableCell>
                                                        <TableCell align="left">Golf - Face On</TableCell>
                                                        <TableCell align="center">
                                                            <Checkbox
                                                                onClick={() => {
                                                                    setChecked([
                                                                        ...checked,
                                                                        v.serviceFulfilmentConfigId,
                                                                    ]);
                                                                }}
                                                                checkedIcon={
                                                                    <Check
                                                                        className={checkboxClasses.checkedIcon}
                                                                    />
                                                                }
                                                                icon={
                                                                    <Check
                                                                        className={checkboxClasses.uncheckedIcon}
                                                                    />
                                                                }
                                                                classes={{
                                                                    checked: checkboxClasses.checked,
                                                                    root: checkboxClasses.checkRoot,
                                                                }}
                                                            />
                                                        </TableCell>

                                                        <TableCell align="right">
                                                            {/*<Button color="info">View</Button>*/}
                                                            <Button
                                                                onClick={() => {
                                                                    alert("Coming soon");
                                                                }}
                                                                color="danger"
                                                            >
                                                                Delete
                                                            </Button>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    )}
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </>
    );
};

export default VideoList;
