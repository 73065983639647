import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { dummyPlayerData } from 'components/PlayerCard/dummyPlayerData';
import { dummyTableData } from 'components/PlayerCard/dummyTableData';
import { columns } from 'components/PlayerCard/dummyTableData';
import PlayerCard from 'components/PlayerCard/PlayerCard';

import { defaultFont } from 'assets/jss/material-dashboard-pro-react';

const useLocalStyles = makeStyles((theme) => ({
    tableContainer: {
        maxHeight: 400,
        color: '#fff',
        "& .MuiTableCell-root": {
            ...defaultFont,
            color: '#fff'
        },
        "& .MuiTableCell-stickyHeader": {
            zIndex: 1
        },
        "& table": {
            whiteSpace: 'nowrap'
        }
    },
    tableHead: {
        "& tr": {
            height: 80
        },
        "& th": {
            backgroundColor: '#282828',
            borderBottom: '1px solid #464646'
        },
        "& th:first-child": {
            borderBottom: 'none !important',
            zIndex: 2
        }
    },
    label: {
        padding: '12px 16px',
        backgroundColor: '#464646',
        color: '#fff'
    },
    labelAdornment: {
        color: '#1EAD9E'
    },
    tableCell: {
        border: '1px solid #464646'
    },
    bodyRows: {
        height: 120,
        "& span": {
            fontWeight: 700,
            fontSize: '1.5rem'
        },
        "& td:first-child": {
            position: 'sticky',
            left: 0,
            zIndex: 1,
            border: 'none !important',
            backgroundColor: '#282828',
            "& span": {
                padding: '12px 16px',
                backgroundColor: '#464646',
                color: '#1EAD9E',
                fontWeight: 300,
                fontSize: '1rem !important',
            }
        }
    }
}));



const TestView = () => {
    const classes = useLocalStyles();

    return (
        <>
            <GridContainer style={{ backgroundColor: "#282828" }} justify="center">
                {dummyPlayerData.map(player => (
                    <GridItem xs={12} md={4}>
                        <PlayerCard key={player.name} player={player} />
                    </GridItem>
                ))}
            </GridContainer>
            {CompareTable(classes)}
        </>
    );
};

export default TestView;
