export const selectData = [
    {
        label: 'Technique',
        name: 'technique',
        options: [
            'Test',
            'Test 2',
            'Test 3'
        ]
    },
    {
        label: 'P Position',
        name: 'pPosition',
        options: [
            'Test',
            'Test 2',
            'Test 3'
        ]
    },
    {
        label: 'Measurement filter',
        name: 'measurementFilter',
        options: [
            'Test',
            'Test 2',
            'Test 3'
        ]
    },
    {
        label: 'Start',
        name: 'start',
        options: [
            'Test',
            'Test 2',
            'Test 3'
        ]
    },
    {
        label: 'Stop',
        name: 'stop',
        options: [
            'Test',
            'Test 2',
            'Test 3'
        ]
    },
    {
        label: 'Uploads',
        name: 'uploads',
        options: [
            'Test',
            'Test 2',
            'Test 3'
        ]
    },
    {
        label: 'Pros',
        name: 'pros',
        options: [
            'Test',
            'Test 2',
            'Test 3'
        ]
    },
    {
        label: 'Display-chart/Dashboard/Comparison',
        name: 'displayChartComparison',
        options: [
            'Test',
            'Test 2',
            'Test 3'
        ]
    },
]