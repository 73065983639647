import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import PricingPage from "views/Pages/PricingPage.js";
import RTLSupport from "views/Pages/RTLSupport.js";
import ReactTables from "views/Tables/ReactTables.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import RegularForms from "views/Forms/RegularForms.js";
import RegularTables from "views/Tables/RegularTables.js";
import SweetAlert from "views/Components/SweetAlert.js";
import TimelinePage from "views/Pages/Timeline.js";
import Typography from "views/Components/Typography.js";
import UserProfile from "views/Pages/UserProfile.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import VectorMap from "views/Maps/VectorMap.js";
import Widgets from "views/Widgets/Widgets.js";
import Wizard from "views/Forms/Wizard.js";

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";
import FileAdd from "components/FileAdd/FileAdd";
import VideoList from "views/VideoList/VideoList";
import PlayArrowIcon from "@material-ui/icons/PlayArrowOutlined";
import TestView from "views/TestView/TestView";
import InsightsTable from "./views/InsightsView";
import {BubbleChart, CloudUpload, CompareArrows, HelpOutline, VideoLibrary} from "@material-ui/icons";
import AnalysisView from "./views/AnalysisView";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/app",
  },
  {
    path: "/uploads",
    name: "Upload Video",
    icon: CloudUpload,
    component: FileAdd,
    layout: "/app",
  },
  {
    path: "/videos",
    name: "Videos",
    icon: VideoLibrary,
    component: VideoList,
    layout: "/app",
  },
  {
    path: "/insights",
    name: "Data Insights",
    icon: BubbleChart,
    component: InsightsTable,
    layout: "/app",
  },
  {
    path: "/insights/:sfcId1/:sfcId2",
    name: "Data Insights",
    icon: BubbleChart,
    component: InsightsTable,
    layout: "/app",
    hideFromSidebar: true
  },
  {
    path: "/analysis/:sfcId1/:sfcId2",
    name: "Analyze",
    icon: CompareArrows,
    component: AnalysisView,
    layout: "/app",
    hideFromSidebar: true
  },
  {
    path: "/analysis",
    name: "Analyze",
    icon: CompareArrows,
    component: AnalysisView,
    layout: "/app",
  },
  /*{
    path: "/test-new-component",
    name: "Test View",
    icon: HelpOutline,
    component: TestView,
    layout: "/app",
  },
  {
    collapse: true,
    name: "Data",
    icon: Image,
    state: "pageCollapse",
    views: [
      {
        path: "/uploads",
        name: "Uploads",
        mini: "PP",
        rtlMini: "ع",
        component: PricingPage,
        layout: "/auth",
      },
    ],
  },*/
];
export default dashRoutes;
