import React, {useEffect, useState} from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { dummyPlayerData } from 'components/PlayerCard/dummyPlayerData';
import { dummyTableData } from 'components/PlayerCard/dummyTableData';
import { columns } from 'components/PlayerCard/dummyTableData';
import PlayerCard from 'components/PlayerCard/PlayerCard';

import { defaultFont } from 'assets/jss/material-dashboard-pro-react';
import { useParams } from "react-router-dom";
import {REPORTSURL} from "../../variables/project";
import { useHistory } from "react-router-dom";

function AnalysisView(props) {

    const { sfcId1, sfcId2 } = useParams();
    const history = useHistory();
    //const sfcId1 = 3099;
    //const sfcId2 = 3147;
    // Hook
    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
            width: undefined,
            height: undefined,
        });
        useEffect(() => {
            // Handler to call on window resize
            function handleResize() {
                // Set window width/height to state
                setWindowSize({
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }
            // Add event listener
            window.addEventListener("resize", handleResize);
            // Call handler right away so state gets updated with initial window size
            handleResize();
            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount
        return windowSize;
    }

    useEffect(() => {
        if(!sfcId1 && !sfcId2)
        {
            history.push("/app/videos")
        }
        console.log(sfcId1);
        console.log(sfcId2);

    },[sfcId1, sfcId2]);

    const size = useWindowSize();

    const [cols, setCols] = useState(columns);
    const [rows, setRows] = useState(dummyTableData);

    const [styleObj, setStyleObj] = useState({});

    useEffect(() => {
        setStyleObj({
            width: (size.width-260-15-15),
            height: (size.height-55-30-15),
            "overflow-y":"scroll",
            "border":"0",
            "margin-top": "-30px",
            "margin-left":"-15px"

        })

    }, [size])
//src="https://reports.eleetz.com/report/3099/3147"
    return sfcId1 && sfcId2 ? <iframe style={styleObj} src={REPORTSURL+sfcId1+"/"+sfcId2}></iframe> : <></>;
   ;
}

export default AnalysisView;
