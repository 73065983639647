import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";

import FileUploadDragDrop from "components/FileUploadDragDrop/FileUploadDragDrop.jsx";
import { APIURL } from "variables/project.js";

import request from "superagent";

import { connect } from "react-redux";

const uploadFiles = function (files, sfcTypeSelection) {
  const prefix = APIURL.replace("/api/", "/labels/");
  var formData = new FormData();

  files.map((file, index) => {
    formData.append(`Files`, file);
  });

  formData.append("Params", 5);

  fetch(prefix + "UploadServiceFulfilmentConfigImage11", {
    // content-type header should not be specified!
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ` + localStorage.getItem("access_token"),
    },
  })
    .then((response) => response.json())
    .then((success) => {
      // Do something with the successful response
    })
    .catch((error) => console.log(error));
};

class FileAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: [],
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={10}>
            <Card>
              <CardHeader color="success" icon>
                <CardIcon color="success">
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle} style={{ paddingLeft: 60 }}>
                  File Upload
                  <small style={{ display: 'block', marginTop: 8 }}>Upload multiple images or a single video to be labelled</small>
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={11}>
                    <FileUploadDragDrop
                      fileList={this.state.fileList}
                      multiple={this.props.sfcTypeSelection == 4}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={11}>
                    <Button
                      color="success"
                      onClick={() => {
                        uploadFiles(
                          this.state.fileList,
                          this.props.sfcTypeSelection
                        );
                      }}
                    >
                      Upload
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps() {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(FileAdd));
